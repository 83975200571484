import {POST} from "@/api/http";

export const post = (params: API.POST.sortParams): Promise<API.POST.sortResponse | unknown> => {
    return POST('/api/category/index', params);
}

// 全局映射类型
declare global {
    namespace API {
        namespace POST {
            // API提交参数
            interface sortParams {
                id: string;
            }

            // API返回数据
            interface sortResponse {
                data: sortItem[];
                message: string;
                success: '1' | '0';
            }

            interface sortItem {
                id: string;
                name: string;
                remark: string;
                type: number;
                date: string;
                timeArr: string[];
                duration: number;
                address: string;
            }
        }
    }
}
